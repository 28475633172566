<script setup lang="ts">
import type { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types'
import type { strapiHomepage } from '~/interfaces/strapi.interface'
import { definePageMeta } from '#imports'
const { findOne } = useStrapi()
const { locale } = useI18n()
const { status } = useAuth()

definePageMeta({ auth: false })

const cms = ref()

const { data } = await useAsyncData('homepage', () =>
  findOne<strapiHomepage>('homepage', {
    populate:
      'hero, interlocutors, interlocutors.interlocutors, interlocutors.interlocutors.image, key_figures, key_figures.key_figures, zigzag, zigzag.items, zigzag.items.image, zigzag.items.button, edito_video, edito_video.media, edito_video.media.media, edito_video.logo, zigzag_solo, zigzag_solo.items, zigzag_solo.items.image, zigzag_solo.items.button, equity, equity.image, fund_life, fund_life.button, fund_life.bloc1_image, fund_life.bloc2_image, fund_life.texts, zigzag_image, zigzag_image.items, zigzag_image.items.image, zigzag_image.items.button, direct, direct.items, direct.image, direct.items.button, seo, seo.metaImage, seo.metaSocial, seo.metaSocial.image, seo.structuredData',
    locale: locale.value as StrapiLocale,
  })
)

if (data.value && data.value.data) cms.value = data.value.data.attributes

if (data.value && data.value.data && data.value.data.attributes.seo) {
  const seo = data.value.data.attributes.seo
  const { meta, link } = useSeo(seo)
  useHead({
    titleTemplate: (titleChunk) => {
      if (seo.metaTitle)
        return titleChunk ? `${titleChunk} - ${seo.metaTitle}` : seo.metaTitle
      return titleChunk
    },
    meta,
    link,
  })
}

onMounted(() => {
  if (
    data.value &&
    data.value.data &&
    data.value.data.attributes &&
    data.value.data.attributes.seo &&
    data.value.data.attributes.seo.structuredData
  ) {
    const script = document.createElement('script')
    script.setAttribute('type', 'application/ld+json')
    script.innerHTML = JSON.stringify(
      data.value.data.attributes.seo.structuredData
    )
    document.head.appendChild(script)
  }
})

const bannerNewClosed = useCookie('bannerNewClosed')
</script>

<template>
  <div v-if="cms" class="home">
    <HomeHero :content="cms.hero" />
    <HomeZigzag id="zigzag" :content="cms.zigzag" />
    <HomeKeyFigures :content="cms.key_figures" />
    <HomeInterlocutors :content="cms.interlocutors" />

    <div class="mt-112 bg-[#F9F9F9] py-112">
      <HomeEditoVideo :content="cms.edito_video" />
      <HomeZigzag id="zigzag-2" :content="cms.zigzag_solo" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home {
  :deep(.edito_video + .zigzag) {
    padding-top: 112px;
  }
}
</style>
