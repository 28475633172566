<script lang="ts" setup>
import type { homeHero } from '~/interfaces/home.interface'

const props = defineProps<{
  content: homeHero
}>()

const text1 = ref()
const text2 = ref()

const text1Full = ref()
const text2Full = ref()

onMounted(() => {
  text1Full.value.innerText = ''
  text2Full.value.innerText = ''

  if (props.content.text_1) {
    props.content.text_1.split(' ').forEach((word, index) => {
      const element = document.createElement('span')
      element.innerHTML = word + '&nbsp;'
      text1Full.value.appendChild(element)
    })

    const split = props.content.text_1.split(' ')
    split.forEach((word, index) => {
      const element = document.createElement('span')
      element.innerHTML = word + '&nbsp;'
      if (text1.value) text1.value.appendChild(element)
      setTimeout(() => {
        element.classList.add('active')
      }, index * 50)
    })
  }

  if (props.content.text_2 && props.content.text_1) {
    props.content.text_2.split(' ').forEach((word, index) => {
      const element = document.createElement('span')
      element.innerHTML = word + '&nbsp;'
      text2Full.value.appendChild(element)
    })

    setTimeout(
      () => {
        const split2 = props.content.text_2.split(' ')
        split2.forEach((word, index) => {
          const element = document.createElement('span')
          element.innerHTML = word + '&nbsp;'
          if (text2.value) text2.value.appendChild(element)
          setTimeout(() => {
            element.classList.add('active')
          }, index * 50)
        })
      },
      props.content.text_1.split(' ').length * 50
    )
  }
})
</script>

<template>
  <section
    v-if="content"
    id="hero"
    data-test="block"
    class="hero bg-primary-500 py-20 lg:py-50"
  >
    <UContainer>
      <h1
        v-if="content.title"
        class="text-h4 mb-24 text-center font-semibold text-white"
      >
        {{ content.title }}
      </h1>
      <div
        v-if="content.text_1 || content.text_2"
        class="text-h1 text-center text-white"
      >
        <div class="relative">
          <component
            :is="content.title ? 'p' : 'h1'"
            v-if="content.text_1"
            ref="text1Full"
            class="text font-semibold opacity-0"
          >{{content.text_1}}</component>
          <div
            v-if="content.text_2"
            ref="text2Full"
            class="text font-extralight opacity-0"
          >{{content.text_2}}</div>
          <div class="absolute left-0 top-0">
            <div v-if="content.text_1" ref="text1" class="text font-semibold" />
            <div
              v-if="content.text_2"
              ref="text2"
              class="text font-extralight"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <img
          class="mt-20 lg:mt-40"
          src="@/assets/images/hero-icon.svg"
          alt="hero icon"
        />
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss">
.hero {
  .text {
    span {
      display: inline-block;
      opacity: 0;
      transform: translateY(10px) rotate(2deg);
      transition: all 0.3s ease-in-out 0.1s;

      &.active {
        transform: none;
        opacity: 1;
      }
    }
  }
}
</style>
