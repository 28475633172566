<script lang="ts" setup>
import type { homeEditoVideo } from '~/interfaces/home.interface'

onMounted(() => {
  const appear = document.querySelectorAll('.appear')
  appear.forEach((appearItem) => {
    window.addEventListener('scroll', () => {
      const rect = appearItem.getBoundingClientRect()
      const visible = rect.top <= (window.innerHeight / 10) * 9
      if (visible) appearItem.classList.add('active')
    })
  })
  const focus = document.querySelectorAll('.focus')
  focus.forEach((focusItem) => {
    window.addEventListener('scroll', () => {
      const rect = focusItem.getBoundingClientRect()
      const visible = rect.top <= (window.innerHeight / 10) * 9
      if (visible) focusItem.classList.add('active')
    })
  })
})

const props = defineProps<{
  content?: homeEditoVideo
}>()

const viewVideo = ref(false)

watch(
  () => viewVideo.value,
)
</script>

<template>
  <section v-if="content" id="edito-video" class="edito_video">
    <UContainer>
      <div class="focus duration-300">
        <AkImage :image="content.logo" class="mb-24" />
      </div>
      <AkText
        v-if="content.text"
        :text="content.text"
        class="appear mb-56 text-p1 duration-300"
      />
      <AkImage
        v-if="content.media.data && !content.video"
        align="center"
        :image="content.media"
      />
      <div v-else-if="content.video" class="focus duration-300">
        <AkRow justify="center">
          <AkCol :cols="12" :cols-lg="10" :cols-xl="9">
            <div
              v-if="(content.media.data || content.video) && !viewVideo"
              class="focus relative duration-300"
              :class="{ 'cursor-pointer': content.video }"
              @click="content.video ? (viewVideo = true) : ''"
            >
              <AkImage
                v-if="content.media.data"
                align="center"
                class="aspect-video"
                :image="content.media"
              />
              <div
                v-else-if="content.video && !viewVideo"
                class="bg-primary aspect-video w-full"
              />
              <UIcon
                v-if="content.video"
                class="absolute left-1/2 top-1/2 h-60 w-60 -translate-x-1/2 -translate-y-1/2 text-white"
                name="i-fa6-solid-play"
              />
            </div>
            <iframe
              v-show="viewVideo"
              ref="vimeoVideo"
              :src="
                viewVideo
                  ? `${content.video
                      .replace('youtu.be', 'youtube.com/embed')
                      .replace('/watch/v=', 'embed/')
                      .replace(
                        'vimeo.com',
                        'player.vimeo.com/video'
                      )}${content.video.indexOf('?') !== -1 ? '&autoplay=1&muted=1' : '?autoplay=1&muted=1'}`
                  : ''
              "
            />
          </AkCol>
        </AkRow>
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
iframe {
  @apply w-full;
  aspect-ratio: 16 / 9;
}
.focus {
  transform: scale(1.1);
  opacity: 0;
  &.active {
    transform: none;
    opacity: 1;
  }
}
.appear {
  opacity: 0;
  transform: translateY(60px);
  &.active {
    transform: none;
    opacity: 1;
  }
}
</style>
