<script lang="ts" setup>
import type { homeZigzag } from '~/interfaces/home.interface'

const target = ref([])

onMounted(() => {
  target.value.forEach((item) => {
    const rect = item.getBoundingClientRect()
    const visible = rect.top >= 0 && rect.bottom <= window.innerHeight
    if (visible) item.classList.add('active')
    else item.classList.remove('active')

    window.addEventListener('scroll', () => {
      const rect = item.getBoundingClientRect()
      const visible =
        rect.top >= 0 && rect.bottom <= (window.innerHeight / 6) * 5
      if (visible) item.classList.add('active')
    })
  })
  const focus = document.querySelectorAll('.focus')
  focus.forEach((focusItem) => {
    window.addEventListener('scroll', () => {
      const rect = focusItem.getBoundingClientRect()
      const visible = rect.top <= (window.innerHeight / 10) * 9
      if (visible) focusItem.classList.add('active')
    })
  })
})

const props = defineProps<{
  content?: homeZigzag
}>()

const splitTitles = ref({})

props.content.items.forEach((item) => {
  if (item.title) {
    const split = item.title.split(' ')
    splitTitles.value[item.id] = split
  }
})
</script>

<template>
  <section v-if="content" class="zigzag">
    <UContainer>
      <div
        v-for="(zigzag, index) in content.items"
        :key="zigzag.id"
        class="mt-112"
      >
        <AkRow :reverse-lg="zigzag.position === 'gauche'">
          <AkCol :cols="12" :cols-lg="6">
            <div class="lg:flex lg:h-full lg:flex-col">
              <h5 v-if="zigzag.on_title" class="text-primary text-h5 mb-24">
                {{ zigzag.on_title }}
              </h5>
              <h4
                v-if="zigzag.title"
                class="text-primary text-h3 font-semibold"
              >
                <span
                  v-for="title in splitTitles[zigzag.id]"
                  ref="target"
                  class="title active inline-block"
                  >{{ title }}&nbsp;
                </span>
              </h4>
              <div class="my-24 h-2 w-62 bg-secondary" />
              <AkText
                v-if="zigzag.text"
                class="text-primary mb-24 text-p1"
                :text="zigzag.text"
              />
              <AkButton
                v-if="zigzag.button"
                class="mt-auto"
                block
                :button="zigzag.button"
              />
            </div>
          </AkCol>
          <AkCol :cols="12" :cols-lg="6">
            <div
              :class="{ 'focus duration-300': index >= 1 }"
              class="lg:h-full"
            >
              <AkImage
                v-if="index >= 1"
                :align="zigzag.position === 'gauche' ? 'start' : 'end'"
                class="rounded-10 object-contain object-center lg:h-full"
                :image="zigzag.image"
              />
              <img
                v-else
                :src="`https://wealtheurazeo.twic.pics/${zigzag.image.data.attributes.hash}${zigzag.image.data.attributes.ext}?twic=v1`"
                alt=""
              />
            </div>
          </AkCol>
        </AkRow>
      </div>
    </UContainer>
  </section>
</template>

<style lang="scss" scoped>
.title {
  color: #ccc;

  &.active {
    @apply text-primary;
  }
}
.focus {
  transform: scale(1.1);
  opacity: 0;
  &.active {
    transform: none;
    opacity: 1;
  }
}
</style>
